import React from 'react';

const Copyright = ({className}) => {
    return (
        <p className={className ? className : ''}>
            © {new Date().getFullYear()} WhitePearl Fashion Trading LLC | Development&nbsp;<a className="text-inherit" href="https://www.hashgate.ae" target="_blank" rel="noopener noreferrer" style={{textDecoration:'underline'}}>HashGate Technology</a>
        </p>
    );
};

export default Copyright;
